import {NavBarItemModel, SelectionControlModel,} from '@core/models/component-models/navbar.model';

export const selectionItems = [
  {key: '1', label: 'a'},
  {key: '2', label: 'b'},
] as SelectionControlModel[];

export const subNavbar = [
  {
    image: 'assets/header-icons/user-icon.png',
    items: [
      {
        label: 'Profile',
        icon: 'pi pi-user',
        key: 'PROFILE',
      },
      {
        label: 'Branches',
        icon: 'pi pi-building',
        items: [
          {
            checkboxGroup: selectionItems,
          },
        ],
      },
      {
        label: 'Map Type',
        icon: 'pi pi-map',
        items: [
          {
            radioGroup: selectionItems,
          },
        ],
      },
      {
        label: 'Sign out',
        icon: 'pi pi-sign-out',
        key: 'SIGN_OUT',

      },
    ],
  },
] as NavBarItemModel[];
