import {Component, forwardRef, Input} from '@angular/core';
import {CheckboxModule} from "primeng/checkbox";
import {TranslateModule} from "@ngx-translate/core";
import {SelectionControlModel} from "@core/models/component-models/navbar.model";
import {BaseControlValueAccessor} from "@core/classes/base-control-value-accessor";
import {FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";

@Component({
  selector: 'app-multi-checkbox',
  standalone: true,
  imports: [CheckboxModule, FormsModule, ReactiveFormsModule, CommonModule, TranslateModule],
  templateUrl: './multi-checkbox.component.html',
  styleUrl: './multi-checkbox.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiCheckboxComponent),
      multi: true,
    },
  ]
})
export class MultiCheckboxComponent extends BaseControlValueAccessor<string[]> {
  @Input() items: SelectionControlModel[] = [];
  @Input() styleClass!: string;
  @Input() alignment: 'row' | 'col' = 'col';

  onValueChange(value: string[]) {
    this.onChange(value);
    this.onTouched();
  }
}
