<div [class]="'flex flex-'+alignment">
  @for (item of items; track item.key) {
    <div class="check-item flex gap-4 p-2" (click)="$event.stopPropagation()">
      <p-radioButton
        [value]="item.key"
        [inputId]="item.key + ''"
        [formControl]="formControl"
        (onClick)="onValueChange($event.value)"
        [styleClass]="styleClass"
      />
      <label [for]="item.key"> {{ item.label }} </label>
    </div>
  }
</div>
